import React, { createRef } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { axiosSetup } from './utils/api';
import { SnackbarProvider } from 'notistack';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import InfoIcon from '@material-ui/icons/Info';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import appconfiguration from './appConfig.json';
import Button from './components/Button/Button';
import WarningIcon from '@material-ui/icons/Warning'; 


// --- Starts  : Global axios middleware interceptor that watches all api requests -- 
axiosSetup();
// --- Ends  : Global axios middleware interceptor that watches all api requests -- 



const theme = {
	palette: {
		primary: {
			main: '#463c97',
		},
		secondary: {
			main: '#32c2d9',
		},
		common: {
			themeGray: '#7a7a7a',
			themeGrayDark: '#7a7a7a',
			themeGrayLight: '#e6e6e6',
			TextMain: 'rgba(0, 0, 0, 0.87)',
		},
		custom:{
			secondaryEmeraldBlue:'#02cbd0',
		},
		typography: {
			"fontFamily": `"Roboto", "Helvetica", "Arial", sans-serif`,
			h1: {
				fontWeight: 500,
				fontSize: '24px'
			}
		},
	},
	overrides: {
		MuiButton: {
			root: {
				textTransform: 'none'
			}
		}
	},

}

// --- Starts  : Material ui theme setup starts  -- 
const CapTheme = createTheme(theme);
// --- Ends  : Material ui theme setup starts  -- 




// --- starts  : Notistack alert configuration -- 
const notistackRef = createRef();
const onClickDismiss = key => () => {
	notistackRef.current.closeSnackbar(key);
}
// --- Ends  : Notistack alert configuration -- 



ReactDOM.render(
	<Provider store={store}>
		<ThemeProvider theme={CapTheme}>
			<PersistGate loading={null} persistor={persistor}>
				<SnackbarProvider
					// autoHideDuration={appconfiguration.notistack_time_out}
					ref={notistackRef}
					maxSnack={4}
					iconVariant={{
						default: <DoneAllIcon className="icon" />,
						success: <DoneAllIcon className="icon successIcon" />,
						error: <ErrorIcon className="icon errorIcon" />,
						warning: <WarningIcon className="icon warningIcon" />,
						info: <InfoIcon className="icon" />,
					}}
					preventDuplicate={true}
					action={(key) => (
						<Button disableElevation className="snackCloseButton" onClick={onClickDismiss(key)}>
							<CloseIcon className="closeIcon" />
						</Button>
					)}
					className="snackNotification"
				>
					<App />
				</SnackbarProvider>
			</PersistGate>
		</ThemeProvider>
	</Provider>,
	document.getElementById('root')
);





// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
