import React, { useState, useEffect, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Checkbox,
  ListItemText,
  TextField,
  MenuItem,
  Paper,
  Chip,
  Button,
} from "@mui/material";
import { useTheme } from "@material-ui/core/styles";

const MultipleSelectInput = ({
  margin = null,
  options = [],
  setselectedValues,
  selectedValues = [],
  width = null,
  label,
  placeholder = "Select",
  emptyMessage = "No options available",
  hiddenLabel = true,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const theme = useTheme();
  useEffect(() => {
    setFilteredOptions(
      options.filter((option) =>
        option.provider__name.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [searchText, options]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside of the dropdown or input field
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    // Listen for clicks on the document
    document.addEventListener("click", handleClickOutside);

    // Cleanup listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const toggleDropdown = () => setIsDropdownOpen((prev) => !prev);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleOptionToggle = (option) => {
    const isSelected = selectedValues.some((item) => item.id === option.id);

    if (isSelected) {
      setselectedValues(selectedValues.filter((item) => item.id !== option.id));
    } else {
      setselectedValues([...selectedValues, option]);
    }
  };

  const isSelected = (id) => selectedValues.some((item) => item.id === id);

  return (
    <div
      style={{
        margin: margin || "8px 0",
        width: width || 300,
        position: "relative",
      }}
    >
      {/* Label */}
      {hiddenLabel && (
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: 8 }}
        >
          <div
            style={{ fontWeight: "bold", marginRight: 8 }}
            className="form-label-settings"
          >
            {label}
          </div>
        </div>
      )}

      {/* Input Box */}
      <div
        onClick={toggleDropdown}
        ref={inputRef}
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "8px",
          cursor: "pointer",
        }}
      >
        {selectedValues.length > 0 ? (
          selectedValues.map((item) => (
            <Chip
              key={item.id}
              label={item.name}
              size="small"
              deleteIcon={<CloseIcon  style={{
    color: theme.palette.secondary.main,
  }} />}
              onDelete={() => handleOptionToggle(item)}
              style={{ margin: "2px" }}
            />
          ))
        ) : (
          <span style={{ color: "#999" }}>{placeholder}</span>
        )}
      </div>

      {/* Dropdown */}
      {isDropdownOpen && (
        <Paper
          ref={dropdownRef}
          style={{
            position: "absolute",
            top: "100%",
            left: 0,
            width: "100%",
            zIndex: 1000,
            maxHeight: "200px",
            overflow: "hidden", 
          }}
        >
          {/* Search Field */}
          <div
            style={{
              position: "sticky", 
              top: 0,
              backgroundColor: "#fff", 
              zIndex: 1,
              padding: "8px",
              borderBottom: "1px solid #ccc",
            }}
          >
            <TextField
              size="small"
              variant="outlined"
              placeholder="Search..."
              label="Search Providers"
              sx={{minWidth: {
                  lg: "700px",
                  md: "700px",
                  sm: "100%",
                  xs: "100%",
                },}}
              value={searchText}
              onChange={handleSearchChange}
            />
          </div>

          {/* Options List */}
          <div
            style={{
              maxHeight: "150px", 
              overflowY: "auto",
            }}
          >
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <MenuItem
                  key={option.id}
                  onClick={() => handleOptionToggle(option)}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Checkbox checked={isSelected(option.id)} />
                  <ListItemText primary={option.name} />
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>
                <ListItemText primary={emptyMessage} />
              </MenuItem>
            )}
          </div>
        </Paper>
      )}
    </div>
  );
};

export default MultipleSelectInput;
