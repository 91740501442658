import React, { useEffect, useMemo, useRef, useState,useCallback } from 'react'
import './DashboardContent.scss'

import DashbaordMailView from '../Dashboard/DasboardMailView/DashboardMailView';
import Modal from '../Modal/Modal';
import { axiosGet } from '../../utils/api';
import { debounce, isNOTNullOrUndefined, truncateString } from '../../utils/utils';
import { useLocation } from 'react-router-dom';
import { Treemap, Tooltip, ResponsiveContainer } from "recharts";
import { CustomTreeComponent } from "./CustomTreeComponent";
import AddandEditEventModal from '../Calendar/AddEditModal/AddEditModal';
import '../Calendar/CalendarMain/CalendarMain.scss';
import moment from 'moment';
import RenderTooltip from "./RenderTooltip";
import Grid from '@material-ui/core/Grid';
import DialogContent from "@material-ui/core/DialogContent";
import Button from "../../components/Button/Button";
import DialogActions from "@material-ui/core/DialogActions";
import usePermissionCheck from '../../hooks/usePermissionCheck';
import CircularLoader from '../CircularLoader/CircularLoader';
import { useDispatch, useSelector } from 'react-redux';
import { isNullOrUndefined } from '@syncfusion/ej2-base';
import { fetchSocialMediums, setCurrentClientType } from '../../redux/dashboard/dashboard.actions';
import { FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Slider, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { fetchEventDataAsync, markParticipationAsync, removeEventData, deleteOrCancelEvent, fetchEventListAsync, clearAddEditEventSuccessMessage, clearEditEventErrorMessage, clearAddEventErrorMessage } from '../../redux/calendar/calendar.actions';
import { setFilesSelectedFromAttachmentModal, removeIndivigualFileItems } from '../../redux/ui/ui.actions';
import { useHistory } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Add, Remove } from "@mui/icons-material";
import { GenerateData } from './data';
import SelectFieldReact from '../SelectField/SelectFieldReact';
import appConfig from '../../appConfig.json';


const clientMailvalue = {
    client_mail: ['shamil@techversantinfotech.com'],
    mail_cc: ['saamil@gmail.com', 'azasda@gmail.com'],
    mail_bcc: ['samil@gmail.com', 'afasfa@gmail.com'],
    subject: 'hey there new subject',
    content: 'dasdas asd asda '
}

const sortOptions = [
    '87FFB9',
    '42C77A',
    '328956',
    'FFA498',
    'D91E1E',
]

const filterOptions = [
    { id: 'all', name: "All Clients" },
    { id: 'Fidelity', name: 'Fidelity' },
    { id: 'Non_Fidelity', name: 'Non Fidelity' },
    { id: 'Fidelity_plus_Non_Fidelity', name: 'Fidelity + Non Fidelity' },
]
const ProfileMangementContent = () => {
  
    const dispatch = useDispatch()
    const is_fetching_socials = useSelector(state => state.dashboard.is_Fetching_social_mediums);
    const social_mediums = useSelector(state => state.dashboard.social_medium_data);
    const currentUserData = useSelector(state => state.auth.currentUserData);
    const clientDetails = useSelector(state => state.client?.clientDetails);
    const eventData = useSelector(state => state.calendar.eventData);
    const addEventData = useSelector(state => state.calendar.addEventData);
    const newEditData = useSelector(state => state.calendar.newEditData);
    const addEventSuccessMessage = useSelector(state => state.calendar.addEventSuccessMessage);
    const editEventSuccessMessage = useSelector(state => state.calendar.editEventSuccessMessage);
    const editErrorMessage = useSelector(state => state.calendar.editErrorMessage);
    const addEventErrorMessage = useSelector(state => state.calendar.addEventErrorMessage);
    const [showMailModal, setShowMailModal] = useState(false)
    const [next, setnext] = useState(true)
    const [deleteProvider,setDeleteProvider] = useState(false)
    const [sortColor, setSortColor] = useState('')
    const [sortOrder, setSortOrder] = useState('')
    const [loading, setLoading] = useState(false);
    const [isAddEVentModalOpen, setIsAddEventModalOpen] = useState({ status: false, isOpenForEditing: false });
    const [data, setData] = useState([])
    const [meetingSchedule, setMeetingSchedule] = useState({})
    const [redirectRequired, setRedirectRequired] = useState(false);
    const [offsetValue, setOffsetValue] = useState(0)
    const [openEventId, setOpenEventId] = useState(null)
    const [selectedCalendarInfo, setSelectedCalendarInfo] = useState(null);
    const [itemRemoved, setItemRemoved] = useState('');
    const [isScheduleMeeting, setIsScheduleMeeting] = useState(false)
    const [modalClosed, setModalClosed] = useState(false);
    const [openEmailErrorModal, setOpenEmailErrorModal] = useState(false);
    const [errorEmailList, setErrorEmailList] = useState([]);
    const [selectedCient, setSelectedClient] = useState(null)
    const [selectedFilter, setSelectedFilter] = useState('all')
    const [zoomLevel, setZoomLevel] = useState(100);
    const [widthValue, setWidthValue] = useState(200); // Example initial width
     const [heightValue, setHeightValue] = useState(150)
     const [containerWidth, setContainerWidth] = useState(0); // Store container dimensions
     const [containerHeight, setContainerHeight] = useState(0);
     const [selectedClientId, setSelectedClientId] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation()
    const client_type_id = location.state
    if (isNullOrUndefined(client_type_id)) {
        dispatch(setCurrentClientType(''))
    }
    const controller = new AbortController();
    const history = useHistory();
   
    const cardParentRef = useRef(null);
    const contentRef = useRef(null);
   
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [scrollTop, setScrollTop] = useState(0);

    const calendarRef = useRef();



    const setErrorToast = (errMessage) => {

        enqueueSnackbar(errMessage, {
            variant: 'error',
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
            },
        });
    }
    useEffect(()=>{
        if(addEventSuccessMessage)
        {
                enqueueSnackbar(addEventSuccessMessage, {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                    onClose: () => {
                        dispatch(clearAddEditEventSuccessMessage())
                    }
                });
        }

        else if(editEventSuccessMessage)
        {
                enqueueSnackbar(editEventSuccessMessage, {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                    onClose: () => {
                        dispatch(clearAddEditEventSuccessMessage())
                    }
                });
        }
        else if(addEventErrorMessage)
        {
                enqueueSnackbar(addEventErrorMessage, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                    onClose: () => {
                        dispatch(clearAddEventErrorMessage())
                    }
                }); 
        }
        else if(editErrorMessage)
        {
                enqueueSnackbar(editErrorMessage, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                    onClose: () => {
                        dispatch(clearEditEventErrorMessage())
                    }
                });
        }
        setIsAddEventModalOpen({ status: false, isOpenForEditing: false });
    },[addEventSuccessMessage, editEventSuccessMessage,addEventErrorMessage,editErrorMessage])
    useEffect(()=>{
        if(location.state)
        {
            const { jumpToEventId, isNewMeeting, clientEmail, eventTitle, taskId, is_draft_meeting } = location.state;
            if(jumpToEventId || isNewMeeting == true){
                setRedirectRequired(true)
            }

            if(isNewMeeting)
            {
                setIsAddEventModalOpen({ status: true, isOpenForEditing: false });
                let attendeesList = [];
                const timestamp = new Date().getUTCMilliseconds();
                attendeesList.push({"id":timestamp, "searchRecipientName":clientEmail})
                setMeetingSchedule({
                    "subject":eventTitle,
                    "attendees":attendeesList,
                    "attachment_list":[],
                    "user":currentUserData.id,
                    "client_id":clientDetails.id,
                    "advisor_id":currentUserData.id,
                    "task_id":taskId ? taskId : null,
                    "description":"",
                    "is_draft_meeting": is_draft_meeting ? is_draft_meeting : null
                })
                setIsScheduleMeeting(isNewMeeting)
            }
            else
            {
                if(jumpToEventId){
                    dispatch(fetchEventDataAsync(jumpToEventId))
                    setOpenEventId(jumpToEventId)
                }
            }
        }
    },[location])

    useEffect(() => {
        dispatch(removeIndivigualFileItems([]))
        if (isAddEVentModalOpen.isOpenForEditing)
        {
            dispatch(setFilesSelectedFromAttachmentModal([...eventData.attachment_list]))
        }
    }, [isAddEVentModalOpen])

    const handleClose = () => {
        setIsAddEventModalOpen({ status: false, isOpenForEditing: false });
        dispatch(removeIndivigualFileItems([]));
        setModalClosed(true);
        // setIsScheduleMeeting(false)
    };

    useEffect(() => {
        if (selectedCalendarInfo !== null)
        {
            setIsAddEventModalOpen({ status: true, isOpenForEditing: false });
        }
    }, [selectedCalendarInfo])

   

    const openEmailErrorModalHandler = (trueOrFalse) => {
        setOpenEmailErrorModal(trueOrFalse);
    }

    useEffect(() => {
        setData([])
        setOffsetValue(0)
        fetchClientsData(0)
        dispatch(fetchSocialMediums())
    }, [sortColor, client_type_id, sortOrder, selectedFilter])
    const abortFetch = () => {
        controller.abort(); // Abort the ongoing request
    };
    const getColorCode = useMemo(() => {
        return (colorCode) => {
            let regx = /#/g;
            return regx.test(colorCode) ? colorCode : '#' + colorCode;
        };
    }, []);


    useEffect(() => {
      if (cardParentRef.current) {
        setContainerWidth(cardParentRef.current.offsetWidth);
        setContainerHeight(cardParentRef.current.offsetHeight);
      }
    }, []);
  
    const handleZoomChange = (newZoomLevel) => {
      const prevZoom = zoomLevel;
      setZoomLevel(newZoomLevel);
  
      if (cardParentRef.current) {
        const container = cardParentRef.current;
        const contentWidth = container.scrollWidth;
        const contentHeight = container.scrollHeight;
  
        const centerX = container.scrollLeft + containerWidth / 2;
        const centerY = container.scrollTop + containerHeight / 2;
  
        const newScrollLeft = centerX * (newZoomLevel / prevZoom) - containerWidth / 2;
        const newScrollTop = centerY * (newZoomLevel / prevZoom) - containerHeight / 2;
  
        container.scrollLeft = newScrollLeft;
        container.scrollTop = newScrollTop;
      }
    };
  
    const handleZoomIn = () => {
      handleZoomChange(Math.min(zoomLevel + 5, 400));
    };
  
    const handleZoomOut = () => {
      handleZoomChange(Math.max(zoomLevel - 5, 100));
    };
  
    const handleSliderChange = (event, newValue) => {
      handleZoomChange(newValue);
    };
  
    const handleReset = () => {
      handleZoomChange(100);
    };
  

  const handleMainScroll = (event) => {
      // This may not be needed anymore with the useEffect above, but keep it if you need it for other scroll handling.
      if (cardParentRef.current) {
          cardParentRef.current.scrollTop = event.target.scrollTop;
          cardParentRef.current.scrollLeft = event.target.scrollLeft;
      }
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - cardParentRef.current.offsetLeft);
    setStartY(e.pageY - cardParentRef.current.offsetTop);
    setScrollLeft(cardParentRef.current.scrollLeft);
    setScrollTop(cardParentRef.current.scrollTop);
};

const handleMouseMove = (e) => {
    if (!isDragging) return;
   // e.preventDefault();
    const x = e.pageX - cardParentRef.current.offsetLeft;
    const y = e.pageY - cardParentRef.current.offsetTop;
    const walkX = (x - startX) * 1; // The multiplier controls the speed of the scroll
    const walkY = (y - startY) * 1;
    cardParentRef.current.scrollLeft = scrollLeft - walkX;
    cardParentRef.current.scrollTop = scrollTop - walkY;
};

const handleMouseUp = () => {
    setIsDragging(false);
};
const handleDoubleClick = useCallback((client) => {
  setSelectedClient(client);
  setSelectedClientId(client.client_id);
  setDeleteProvider(true);
}, []);

    const getTextColor = (backgroundColor) => {
        // Convert the hex color to RGB
        const r = parseInt(backgroundColor.slice(0, 2), 16);
        const g = parseInt(backgroundColor.slice(2, 4), 16);
        const b = parseInt(backgroundColor.slice(4, 6), 16);
    
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;
      // Choose black or white based on the brightness
        return brightness > 128 ? 'black' : 'white';
    };
    async function fetchClientsData(currentOffset) {
        setLoading(true);
        const url = `dashboard-metrix/?ordering=-ratio${isNOTNullOrUndefined(client_type_id) ? `&client_type=${client_type_id}` : ''}${isNOTNullOrUndefined(sortColor) ? `&color_code=${sortColor}` : ''}${isNOTNullOrUndefined(selectedFilter) && selectedFilter != 'all' ? `&instrument=${selectedFilter}` : ''}`;
        try {
            const response = await axiosGet(url, { signal: controller.signal });
            if (response.data.data) {
                const formattedData = response.data.data.map(i => ({
                    name: `${i.client.user.first_name} ${i.client.user.last_name}`,
                    size: i.ratio + 1,
                    color: getColorCode(i.current_color),
                    last_email_sent: i.last_email_sent,
                    email: i.client.user.email,
                    client_id: i.client.id,
                    aum: formatAum(i.client.aum),
                    socials: i.client.social_mediums,
                    relative_client_name: i.client.relative_client_name,
                    first_name: i.client.user.first_name,
                    last_name: i.client.user.last_name,
                    advisor: i.client.advisor
                }));
                setData(formattedData);
            } else {
                setData([]);
            }
        } catch (error) {
            if (error.name == 'AbortError') {
                enqueueSnackbar("Previous request cancel", {
                    variant: "error",
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center'
                    }
                  })
            } else {
                enqueueSnackbar("Error to fatching data", {
                    variant: "error",
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center'
                    }
                  })
            }
        } finally {
            setLoading(false);
        }
    }
    const formatAum = (aumValue) => {
        if (aumValue < 1000000) {
            return `${(aumValue / 1000).toFixed(0)}k`;
        } else {
            return `${(aumValue / 1000000).toFixed(1)}m`;
        }
    };

    const clearAllFilters = () => {
        //dispatch(setCurrentClientType(''))
     //   setSortOrder('')
        setSortColor('')
        // setSelectedFilter('all')
        // history.replace({ ...history.location, state: undefined });
    }



    if (is_fetching_socials) return <div className='loader'> <CircularLoader className="pageCirclularLoader" /></div>
    return (
        <div className='content-body'>
            <div className="top-bar">
                <p className="title">AUM figures</p>
                <div style={{ width: '1px', height: '100%', background: '#848484' }} />
                <p className="text-style">Sort by :</p>
                {sortOptions.map((i, index) => <div onClick={() => setSortColor(i)} key={index} className='sort-btn' style={{ background: getColorCode(i) ,  color: getTextColor(i)}} >{index + 1}</div>)}
                {/* <div className='sort-btn-2' onClick={() => setSortOrder('client__user__first_name')} >
                    A-Z
                </div>
                <div className='sort-btn-2' onClick={() => setSortOrder('-client__user__first_name')}  >
                    Z-A
                </div> */}
              {!loading ?    <div style={{ width: "auto" }}>
                    <SelectFieldReact options={filterOptions.map(i => {
                        return { value: i.id, label: i.name }
                    })} value={selectedFilter} onChange={(e) => {
                        setSelectedFilter(e.value)
                    }} />
                </div>: null}
                {
                // isNOTNullOrUndefined(client_type_id) || isNOTNullOrUndefined(sortOrder) || 
                isNOTNullOrUndefined(sortColor) 
                // || selectedFilter != 'all'
                 ? <div className='clear-btn' onClick={() => clearAllFilters()}>
                    Clear Filters
                </div> : null}

                <div >
<Box
sx={{
display: "flex",
alignItems: "center",
gap: "10px",
}}
>
{/*-------| Zoom Count |-------*/}
<Typography
sx={{
width: "2.5rem",
fontSize: "16px",
fontWeight: "400",
color: "#000",
}}
>
{zoomLevel}%
</Typography>

{/*-------| Minus Btn |-------*/}
<IconButton size="small" onClick={handleZoomOut}>
<Remove />
</IconButton>

{/*-------| Slider |-------*/}
<Slider
min={100}
max={400}
step={5}
sx={{ width: "150px" }}
value={zoomLevel}
onChange={handleSliderChange}
/>

{/*-------| Plus Button |-------*/}
<IconButton size="small" onClick={handleZoomIn}>
<Add />
</IconButton>

{/*-------| Reset Button |-------*/}
<Button variant="contained" size="small" onClick={handleReset}   bgColor="secondary"  width= '140px' >
<RestartAltIcon fontSize="small"/>
Reset
</Button>
</Box>
</div> 
            </div>
            <Box
  ref={cardParentRef}
  sx={{
    width: "100%",
    height: "75vh",
    bgcolor: "#fff",
    overflow: "auto",
    borderRadius: "2px",
    border: "1px solid #000",
    cursor: isDragging ? 'grabbing' : 'grab',
    //-------| Scroll Bar |-------//
    "&::-webkit-scrollbar": {
      width: "10px",
      height: "10px",
      display: "block",
      backgroundColor: "#b6b6b6",
      borderRadius: "50px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#eeeeee",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#b6b6b6",
      borderRadius: "50px",
    },
  }}
  onMouseDown={handleMouseDown}
  onMouseMove={handleMouseMove}
  onMouseUp={handleMouseUp}
  onMouseLeave={handleMouseUp}
  onScroll={handleMainScroll}
>

  {loading && (
    <div className="loader" style={{
      position: "absolute", 
      top: "60%", 
      left: "50%", 
      transform: "translate(-50%, -50%)",
      zIndex: 1, 
    }}>
      <CircularLoader className="pageCirclularLoader" />
    </div>
  )}

 
  <Box
    ref={contentRef}
    sx={{
      width: `${widthValue * zoomLevel / 100}%`,
      height: `${heightValue * zoomLevel / 100}vh`,
      display: "flex",
      bgcolor: "#fff",
      flexWrap: "wrap",
      transform: `scale(${zoomLevel / 100})`,
      transformOrigin: "0% 0%",
      overflow: "hidden",
    }}
  >
    {data?.length > 0 ? (
      <ResponsiveContainer width="100%" height="100%">
        <Treemap
          data={data}
          dataKey="size"
          stroke="#fff"
          fill="orange"
          content={
            <CustomTreeComponent
            onDoubleClick={handleDoubleClick}
              zoomLevel={zoomLevel}
            />
          }
          isAnimationActive={false}
          aspectRatio={1 / 1}
        >
          <Tooltip content={<RenderTooltip zoomLevel={zoomLevel} />}   position={{ vertical: 'top', horizontal: 'center' }}   active={true}/>
        </Treemap>
      </ResponsiveContainer>
    ) : !loading ? (
      <div className="loader">
        <p>No data</p>
      </div>
    ) : null}
  </Box>
</Box> 
            <Modal
        open={deleteProvider}
        handleClose={() => setDeleteProvider(false)}
        size="sm"
        className="deleteModal"
      >
        <DialogContent>
          <h2 className="snoozeHead customHead" style={{ textAlign: 'center' }}>
          {selectedCient && selectedCient.first_name && selectedCient.last_name
        ? `${selectedCient.first_name} ${selectedCient.last_name}`
        : "Client Name"}
          </h2>
          <div className="snoozeSubHeadWrapper">
            <h2 className="snoozeSubHead discardMessage customDescription">{" "}</h2>
            <hr />
            <div className="snoozeChangeSelectWrapper">
              <Grid container spacing={2}>
                <Grid item xs={12} className="deleteMessage" style={{ textAlign: 'center',fontSize: '1rem' }}>
                 Do you want to catch up or schedule a meeting with this client?
                 
                </Grid>
              </Grid>
            </div>
          </div>
          <DialogActions className="modalFooter"> 
          <Button
              className="deleteBtn"
              variant="contained"
              size="small"
              bgColor="secondary"
              onClick={() => setShowMailModal(true)}
            
            >
              Catch up mail
            </Button> 
          <Button
              className="deleteBtn"
              variant="contained"
              size="small"
              bgColor="secondary"
              onClick={() => {
                setIsAddEventModalOpen({ status: true, isOpenForEditing: false });

                let attendeesList = [];
                const timestamp = new Date().getUTCMilliseconds();
                attendeesList.push({ id: timestamp, searchRecipientName: selectedCient?.email });
                const { taskId, is_draft_meeting } = location.state || {};

                // Set the meeting schedule state with the selected client details
                setMeetingSchedule({
                  subject: [],
                  attendees: attendeesList,
                  attachment_list: [],
                  user: currentUserData?.id,
                  client_id: selectedCient?.client_id,
                  advisor_id: currentUserData?.id,
                  task_id: taskId ? taskId : null,
                  description:"",
                  is_draft_meeting: is_draft_meeting ? is_draft_meeting : null
                });
            
                // Ensure modal opens for scheduling a meeting
                setIsScheduleMeeting(true);

            }}
            
            >
              Schedule meeting
            </Button>
            <Button
              className="cancelBtn"
              variant="contained"
              size="small"
              bgColor="success"
              onClick={() => setDeleteProvider(false)}
            >
              Cancel
            </Button>
          
          </DialogActions>
        </DialogContent>
        <AddandEditEventModal
                isAddEVentModalOpen={isAddEVentModalOpen}
                setIsAddEventModalOpen={setIsAddEventModalOpen}
                eventData={isScheduleMeeting ? meetingSchedule : eventData}
                selectedCalendarInfo={selectedCalendarInfo}
                handleClose={handleClose}
                {...(isScheduleMeeting ?{addZoomLinkButton:true}:{})}
              //  calendarRef={calendarRef}
                openEmailErrorModalHandler={openEmailErrorModalHandler}
                setErrorEmailList={setErrorEmailList}
            />
      </Modal>
            <Modal open={showMailModal} handleClose={() => setShowMailModal(false)} >
                <DashbaordMailView
                    afterSuccessfullySent={() => {
                        setShowMailModal(false)
                        fetchClientsData()
                    }}
                    client_details={selectedCient} close={() => setShowMailModal(false)} className="mailModal" clientMailvalue={clientMailvalue} />
            </Modal>
        </div>
    )
}

export default ProfileMangementContent